import { defineNuxtRouteMiddleware, navigateTo } from 'nuxt/app'
import { usePartyStore } from '~/store/party'

export default defineNuxtRouteMiddleware((to) => {
  if (to.name === 'party-uuid-journal') {
    const $store = usePartyStore()
    if (!$store.id && !$store.name) {
      return
    }
    const entries = $store.journal.sort((a, b) => {
      return (
        new Date(b.sessionDate).getTime() - new Date(a.sessionDate).getTime()
      )
    })
    if (entries.length) {
      return navigateTo({
        name: 'party-uuid-journal-journal',
        params: { journal: entries[0].id },
      })
    } else {
      return navigateTo({ name: 'party-uuid-journal-new' })
    }
  }
})
